// Third party
import React from "react";
import * as PropTypes from "prop-types";
import { graphql } from "gatsby";

// Components
import Layout from "../components/Layout";
import Container from "../components/Container";
import Section from "../components/Section";
import Breadcrumb from "../components/Breadcrumb";
import Seo from "../components/seo";

class TeacherTemplate extends React.Component {
  render() {
    const teacher = this.props.data.contentfulTeacher;
    const {
      title,
      slug,
      body: {
        childMarkdownRemark: { excerpt, html },
      },
    } = teacher;
    const breadcrumbs = [
      {
        id: 1,
        title: "Home",
        slug: "/",
      },
      {
        id: 2,
        title: "Docenten",
        slug: "/teachers",
      },
      {
        id: 3,
        title: title,
        slug: `/teachers/${slug}`,
      },
    ];
    return (
      <Layout>
        <Seo
          title={`${title}`}
          description={excerpt}
          slug={`teachers/${slug}`}
        />
        <Breadcrumb items={breadcrumbs} />
        <Section>
          <Container>
            <div className="grid">
              <div className="grid__col grid__col--3-of-5 grid__col--centered">
                <h1>Over {title}</h1>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            </div>
          </Container>
        </Section>
      </Layout>
    );
  }
}

TeacherTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TeacherTemplate;

export const pageQuery = graphql`
  query teacherQuery($slug: String!) {
    contentfulTeacher(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      avatar {
        fixed(width: 224, height: 224) {
          src
        }
      }
      email
      website
      telephoneNumber
    }
  }
`;
